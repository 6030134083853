<!--投标保证金贷款列表-->
<template>
  <div class="list-view">
    <div class="crumbs">
      <a href="###">首页</a>
      <label>></label>
      <a href="###">借款申请</a>
      <label>></label>
      <a href="###">投标保证金贷款列表</a>
    </div>
    <div class="search-filter-group">
      <el-form inline label-width="70px" label-position="left" size="small">
        <el-form-item label="采购方">
          <el-input v-model="form.purchaseOrg" clearable></el-input>
        </el-form-item>
        <el-form-item label="项目名称">
          <el-input v-model="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="招标机构">
          <el-input v-model="form.bidOrg" clearable></el-input>
          <!-- <el-button type="primary">选择</el-button> -->
        </el-form-item>
        <el-form-item label="代码">
          <el-input v-model="form.bidCode" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <!-- <el-input v-model="form.status" clearable></el-input> -->
          <el-select v-model="form.status" clearable placeholder="">
            <el-option
              v-for="(item, key) in statusList"
              :key="key"
              :value="key"
              :label="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-button
          type="primary"
          size="small"
          style="width: 151px; float: right; margin-right: 15px"
          @click="getList(1)"
          >搜索</el-button
        >
      </el-form>
    </div>

    <div style="margin-top: 16px">
      <el-table :data="list" size="small" v-loading="loading" class="qh-table">
        <el-table-column prop="purchaseOrg" label="采购方"></el-table-column>
        <el-table-column prop="name" label="项目名称"></el-table-column>
        <el-table-column prop="bidOrg" label="招标机构"></el-table-column>
        <el-table-column prop="bidCode" label="代码"></el-table-column>
        <el-table-column prop="margin" label="投标保证金"></el-table-column>
        <el-table-column
          prop="purchaseMoney"
          label="预期采购金额"
        ></el-table-column>
        <el-table-column prop="loanMoney" label="借款金额"></el-table-column>
        <el-table-column prop="status" label="贷款状态">
          <template slot-scope="scope">
            {{ statusList[scope.row.status] }}
          </template>
        </el-table-column>
        <el-table-column prop="auditMoney" label="批复额度"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="scope.row.status == 0"
              @click="updateBidProject(scope.row.id)"
              >更新</el-button
            >
            <el-button type="text" @click="lookDetail(scope.row.id)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination">
        <el-button type="primary" size="small" @click="updateBidProject()"
          >新增投标项目</el-button
        >
        <el-button size="small">返回</el-button>
        <el-pagination
          :current-page="page.pageNum"
          background
          layout="prev, pager, next"
          :total="page.total"
          @current-change="getList"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      statusList: {
        0: "草稿",
        1: "提交审核",
        2: "审批通过",
        3: "审批不通过",
        4: "确认放款",
      },
      form: {
        purchaseOrg: "",
        name: "",
        bidOrg: "",
        bidCode: "",
        status: "",
      },
      page: {
        pageNum: 1,
        total: 0,
      },

      list: [],
      loading: false,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    updateBidProject(id) {
      this.$router.push(
        `/a-company/loan-application/bid-margin/apply/${id ? id : 0}`
      );
    },
    lookDetail(id) {
      this.$router.push(
        `/a-company/loan-application/bid-margin/detail/${id ? id : 0}`
      );
    },
    getList(current) {
      this.loading = true;
      this.$axios
        .get("/marginloan/getDataList", {
          params: {
            pageNum: current ? current : this.page.pageNum,
            purchaseOrg: this.form.purchaseOrg,
            name: this.form.name,
            bidOrg: this.form.bidOrg,
            bidCode: this.form.bidCode,
            status: this.form.status,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 200) {
            this.list = res.data.list;
            this.page.pageNum = res.data.pageNum;
            this.page.total = res.data.total;
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.list-view {
  margin-bottom: 60px;
}
.search-filter-group {
  border: 1px solid #ededed;
  padding: 23px;
}
.pagination {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}
</style>